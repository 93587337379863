import React from 'react';
import ErrorImg from '../images/Error.svg'
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

function NotFoundPage() {
	return (
		<Layout>
			<SEO title="404: Not found" />
			<div>
				<img src={ErrorImg} alt="Error" className="mx-auto mb-12 w-2/5 md:w-auto"/>
				<h2 className="text-center text-2xl mb-2 md:text-4xl">Sorry - Page Not Found</h2>
				<p className="text-center text-sm mb-12 md:text-xl">It looks like the page you were searching for doesn't exist. Please check the URL and try again or use the link below to get back to the site. </p>
				<div className="flex">
				<Link to="/" className="mx-auto mb-12 text-center bg-wtblue text-center text-white text-md font-bold py-2 px-4 rounded w-48 transition duration-500 ease-in-out hover:bg-wtbluehover xl:text-xl xl:w-56">Home Page</Link>

				</div>
			</div>
		</Layout>
	);
}

export default NotFoundPage;
